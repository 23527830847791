import React, { type FunctionComponent, type PropsWithChildren, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { doIfDefined } from '../../helpers/general-helper/general-helper';
import { handleKeys } from '../../helpers/keyboard/keyboard.helper';
import { ClickableElement } from '../buttons';
import { ChevronLeftIcon, ChevronRightIcon } from '../svg/icons.component';

type CarouselButtonProps = {
	onClick: () => void;
	arrowDirection: 'left' | 'right';
	// the default is to hide the navigation when reaching either ends, when this is true, it'll lock and dim the buttons
	shouldLock?: boolean;
	navigationVerticalPosition?: number;
	isMediaGallery?: boolean;
};
export const CarouselButton: FunctionComponent<CarouselButtonProps> = ({
	arrowDirection,
	onClick,
	shouldLock = false,
	navigationVerticalPosition = 0,
	isMediaGallery
}) => {
	const chevronClassName = `f3 ${arrowDirection === 'left' ? 'mr1' : 'ml1'} mt1`;
	return (
		<div className={`${isMediaGallery ? '' : 'absolute'} z-2 top-0 ${arrowDirection}-0 h-100`}>
			<div
				className={`flex flex-column justify-center ${shouldLock ? 'o-50' : ''} h-100 ${isMediaGallery ? '' : 'pv3 ph4 pa3-ns'}`}
				style={{
					background: `linear-gradient(to ${arrowDirection}, transparent 0%, white 100%)`
				}}>
				<span
					style={{
						transform: `translate(0, ${navigationVerticalPosition}%)`,
						pointerEvents: `${isMediaGallery && shouldLock ? 'none' : 'auto'}`
					}}>
					<ClickableElement
						ariaLabel={`${arrowDirection} navigation button`}
						className={`flex ${
							shouldLock ? 'not-allowed o-50' : 'pointer'
						} flex-column items-center br-100 pa2 shadow-1 bg-theme-white`}
						onClick={onClick}
						onKeyDown={handleKeys(['Enter', ' '], onClick)}
						tabIndex={0}>
						{arrowDirection === 'left' ? (
							<ChevronLeftIcon className={chevronClassName} />
						) : (
							<ChevronRightIcon className={chevronClassName} />
						)}
					</ClickableElement>
				</span>
			</div>
		</div>
	);
};

type CarouselProps = {
	// the default is to hide the navigation when reaching either ends
	shouldLockNavigation?: boolean;
	navigationVerticalPosition?: number;
	cardWidth?: number;
	rootMargin?: string;
};

export const Carousel: FunctionComponent<PropsWithChildren<CarouselProps>> = ({
	children,
	shouldLockNavigation = false,
	navigationVerticalPosition,
	cardWidth,
	rootMargin = ''
}) => {
	const carouselContainerRef = useRef<HTMLDivElement>(null);
	const { ref: carouselEndRef, inView: carouselEndRefInView } = useInView({
		root: carouselContainerRef.current,
		initialInView: true,
		threshold: 1,
		rootMargin
	});
	const { ref: carouselStartRef, inView: carouselStartRefInView } = useInView({
		root: carouselContainerRef.current,
		initialInView: true,
		threshold: 1
	});
	// offset x in percentage
	const [offsetX, setOffsetX] = useState<number>(0);
	// Safari returns different values of scrollWidth, which causes weird behavior when swiping using both touch and arrow buttos
	// that's why it is needed to be set once
	const minOffsetXRef = useRef<number | null>(null);

	// this is needed for when both touch and buttons are available (iPad landscape)
	const transitionSpeed = useRef<string>('all 0.05s linear');

	// Mobile touch events refs
	const touchStartXRef = useRef<number>(0);
	const touchMoveXRef = useRef<number>(0);
	const touchEndXRef = useRef<number>(0);

	const arrowButtonWidthOffset = 100;

	const verifyOffsetValue = (offset: number, shouldUpdateTouchEndRef: boolean) => {
		doIfDefined(carouselContainerRef.current, (carouselContainer) => {
			// set the value once
			let minOffsetX = minOffsetXRef.current;
			if (!minOffsetX) {
				minOffsetXRef.current = carouselContainer.offsetWidth - carouselContainer.scrollWidth;
				minOffsetX = minOffsetXRef.current;
			}
			if (minOffsetX) {
				let verifiedOffset = offset;
				const maxOffsetX = 0;
				// locks carousel at the begining and end, if the offset is bigger than the max, then stop at the max and viceversa for the min
				// this may look reversed, but it's intentional because of the touch swipe gesture
				if (offset > maxOffsetX) {
					verifiedOffset = maxOffsetX;
				}
				if (offset < minOffsetX) {
					verifiedOffset = minOffsetX;
				}
				setOffsetX(verifiedOffset);
				if (shouldUpdateTouchEndRef) {
					touchEndXRef.current = verifiedOffset;
				}
			}
		});
	};

	const slideForward = () => {
		doIfDefined(carouselContainerRef.current, (carouselContainer) => {
			// button transition needs to be slower with ease in and out for button sliders
			transitionSpeed.current = 'all 0.5s ease-in-out';
			// multiplying with -1 to reverse the values to work with the logic in the verifyOffsetValue function to move in the right direction
			const translateDist = offsetX + -1 * (cardWidth ?? carouselContainer.offsetWidth - arrowButtonWidthOffset);
			verifyOffsetValue(translateDist, true);
		});
	};

	const slideBackward = () => {
		doIfDefined(carouselContainerRef.current, (carouselContainer) => {
			transitionSpeed.current = 'all 0.5s ease-in-out';
			const translateDist = offsetX + (cardWidth ?? carouselContainer.offsetWidth - arrowButtonWidthOffset);
			verifyOffsetValue(translateDist, true);
		});
	};

	const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
		// touch transition needs to be faster with linear to keep up with the speed of the finger
		transitionSpeed.current = 'all 0.05s linear';
		touchStartXRef.current = e.targetTouches[0].clientX;
		touchMoveXRef.current = e.targetTouches[0].clientX;
	};

	const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
		touchMoveXRef.current = e.targetTouches[0].clientX;
		const translateDist = touchEndXRef.current + (touchMoveXRef.current - touchStartXRef.current);
		verifyOffsetValue(translateDist, false);
	};

	const handleTouchEnd = () => {
		// saves last position of the touch swipe for the next time
		touchEndXRef.current = offsetX;
	};

	return (
		<div className="relative">
			<div
				className="flex overflow-hidden"
				style={{ scrollBehavior: 'smooth', touchAction: 'pan-y' }}
				data-testid="carousel-container"
				ref={carouselContainerRef}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}>
				<div
					className="flex"
					data-testid="children-container"
					style={{
						transform: `translateX(${offsetX}px)`,
						transition: transitionSpeed.current
					}}>
					<div ref={carouselStartRef}></div>
					{children}
					<div ref={carouselEndRef}></div>
				</div>
				{(!carouselStartRefInView || shouldLockNavigation) && (
					<CarouselButton
						arrowDirection="left"
						onClick={carouselStartRefInView ? () => {} : slideBackward}
						shouldLock={shouldLockNavigation && carouselStartRefInView}
						navigationVerticalPosition={navigationVerticalPosition}
					/>
				)}
				{(!carouselEndRefInView || shouldLockNavigation) && (
					<CarouselButton
						arrowDirection="right"
						onClick={carouselEndRefInView ? () => {} : slideForward}
						shouldLock={shouldLockNavigation && carouselEndRefInView}
						navigationVerticalPosition={navigationVerticalPosition}
					/>
				)}
			</div>
		</div>
	);
};
