import React, { type FunctionComponent } from 'react';
import { SHOP_BY_LOOK_LINK } from '../../../constants/links';
import { BASE_VIEW_ALL_URL } from '../../../constants/showroom/carousel';
import { type CarouselItem } from '../../../helpers/carousel/carousel.helper';
import { Carousel } from '../../carousel/carousel.component';
import { type DefaultCarouselCardProps } from '../../carousel-components/default-carousel-card/default-carousel-card.component';
import { Link } from '../../common-components/link/link.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { ArrowRightAltIcon } from '../../svg/icons.component';

export type ShopByLookCarouselItem = CarouselItem & {
	url: string;
};

export const ShopByLookCarouselCard: FunctionComponent<DefaultCarouselCardProps<ShopByLookCarouselItem>> = ({
	item,
	cloudinaryOptions,
	ariaLabel
}) => {
	const { id, description, url } = item;

	return (
		<div className="dib" aria-label={ariaLabel}>
			<Link url={url}>
				<CloudinaryImage publicID={id} description={`${description} styled room`} options={cloudinaryOptions} className={'br2'} />
				<p className="mv2 theme-black">{item.description}</p>
			</Link>
		</div>
	);
};

export type ShopByLookCarouselProps = {
	items: CarouselItem[];
};

export const ShopByLookCarousel: FunctionComponent<ShopByLookCarouselProps> = ({ items }) => {
	return (
		<>
			<div className="relative flex items-center justify-center mb2">
				<p className="f2 fw6 tc mt0 mb2">Shop by Look</p>
				<Link className="dn flex-ns absolute right-2-ns items-center" url={SHOP_BY_LOOK_LINK} ariaLabel="View all looks">
					<span className="pr1">View All</span>
					<ArrowRightAltIcon />
				</Link>
			</div>
			<div>
				<Carousel shouldLockNavigation>
					<div className="flex flex-shrink-0 gc3">
						{items.map((item, index) => (
							<ShopByLookCarouselCard
								key={`${item.id}-${index}`}
								item={{ ...item, url: `${BASE_VIEW_ALL_URL}${item.description}` }}
								cloudinaryOptions={{ width: 256, height: 170, crop: 'lpad' }}
								ariaLabel={''}
							/>
						))}
					</div>
				</Carousel>
			</div>
		</>
	);
};
